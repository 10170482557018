import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Property, PropertyIdPaused} from '../../../model/Property';
import {Photo} from '../../../model/secondary/Photo';
import {PropertyBooking} from '../../../model/PropertyBooking';
import {Person} from '../../../model/Person';
import {
  PropertyForMultipleListDto,
  PropertyForMultipleListDtoListResponse
} from '../../../model/PropertyForMultipleListDto';
import {flatMap} from 'rxjs/internal/operators';
import {PropertyForCalendarDto} from "../../../model/PropertyForCalendarDto";
import {PropertySearchRequest} from "../../../model/modeldto/PropertySearchRequest";
import {OptimizedPropertySearchRequest} from "../../../model/modeldto/OptimizedPropertySearchRequest";
import {NumberToProperties} from "../../../model/modeldto/NumberToProperties";
import {SearchSimplePropertyRequest} from "../../../model/SearchSimplePropertyRequest";
import {Page} from "../../../model/Page";
import {PropertySimpleDto} from "../../../model/PropertySimpleDto";



@Injectable({ providedIn: 'root' })
export class PropertyEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }

  createProperty(property: Property): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property', property, this.httpService.getHeaders(to));
    }));
  }
  updateProperty(property: Property): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property', property, this.httpService.getHeaders(to));
    }));
  }
  deleteProperty(property: Property): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/' + property.id, this.httpService.getHeaders(to));
    }));
  }
  deletePropertyById(propertyId: string): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/' + propertyId, this.httpService.getHeaders(to));
    }));
  }
  getMyProperty(): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property[]>( this.confService.getUrlApiEndPoint() + 'api-v2/property', this.httpService.getHeaders(to));
    }));
  }

  findForMultipleListOptimized(optimizedPropertySearchRequest: OptimizedPropertySearchRequest) : Observable<PropertyForMultipleListDto[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<PropertyForMultipleListDto[]>( this.confService.getUrlApiEndPoint() + 'api-v2/property/for-multiple-list-optimized', optimizedPropertySearchRequest, this.httpService.getHeaders(to));
    }));
  }


  findForMultipleListOptimizedWithPage(optimizedPropertySearchRequest: OptimizedPropertySearchRequest) : Observable<PropertyForMultipleListDtoListResponse> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<PropertyForMultipleListDtoListResponse>( this.confService.getUrlApiEndPoint() + 'api-v2/property/for-multiple-list-optimized-page', optimizedPropertySearchRequest, this.httpService.getHeaders(to));
    }));
  }

  findForMultipleList(): Observable<PropertyForMultipleListDto[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<PropertyForMultipleListDto[]>( this.confService.getUrlApiEndPoint() + 'api-v2/property/for-multiple-list', this.httpService.getHeaders(to));
    }));
  }

  findForCalendarList(search: PropertySearchRequest): Observable<PropertyForCalendarDto[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<PropertyForCalendarDto[]>( this.confService.getUrlApiEndPoint() + 'api-v2/property/for-calendar-list', search ,this.httpService.getHeaders(to));
    }));
  }

  countMyProperties(): Observable<number> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<number>( this.confService.getUrlApiEndPoint() + 'api-v2/property/count', this.httpService.getHeaders(to));
    }));
  }

  getPropertyById(id: string): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/' + id, this.httpService.getHeaders(to));
    }));
  }
  mergeProperties(firstPropertyId: string , secondPropertyId: string): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/merge/' + firstPropertyId + '/' + secondPropertyId, null, this.httpService.getHeaders(to));
    }));
  }
  getPropertiesToMerge(): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/merge' , this.httpService.getHeaders(to));
    }));
  }
  setMainPicture(propertyId: string, pictureUrl: string): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/photo/' + propertyId, pictureUrl, this.httpService.getHeaders(to));
    }));
  }
  setHygiene(propertyId: string, codes: string[]): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/hygiene/' + propertyId, codes, this.httpService.getHeaders(to));
    }));
  }
  copyToProperties(numberToProperties: NumberToProperties): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<void>(this.confService.getUrlApiEndPoint() + 'api-v2/hygiene/exportToProperties', numberToProperties, this.httpService.getHeaders(to));
    }));
  }

  setPictures(propertyId: string, photos: Photo[]): Observable<void> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/photos/' + propertyId, photos, this.httpService.getHeaders(to));
    }));
  }
  setCaptionPicture(propertyId: string, pictureUrl: string, captionPicture: string): Observable<void> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('caption', captionPicture);
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/photo/caption/' + propertyId, pictureUrl, this.httpService.getOptionsWithParams(to, httpParams));
    }));
  }
  createPropertyFromAirbnb(listingId: string): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/fromairbnb/create/' + listingId,  this.httpService.getHeaders(to));
    }));
  }
  enableDisableAction(propertyId: string, flag: boolean): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/actions/' + propertyId + '/' + flag, null, this.httpService.getHeaders(to));
    }));
  }
  getCurrentPropertyBooking(propertyId: string): Observable<PropertyBooking> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<PropertyBooking>(this.confService.getUrlApiEndPoint() + 'api-v2/propertybooking/current/' + propertyId, this.httpService.getHeaders(to));
    }));
  }
  createPropertyBookletFromAirbnb(url: string, prospect: Person): Observable<PropertyBooking> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('url', url);
    return this.http.post<PropertyBooking>(this.confService.getUrlApiEndPoint() + 'api-v2/property/fromairbnb/generatebooklet', prospect, this.httpService.getSimpleOptionsWithParams(httpParams));
  }

  pauseUnPauseProperties(properties: PropertyIdPaused[]): Observable<Property[]> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/subscriptionpaused', properties, this.httpService.getHeaders(to));
    }));
  }

  testAllEmails(propertyBookingId: string): Observable<any> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<any>(this.confService.getUrlApiEndPoint() + 'api-v2/property/testemail/' + propertyBookingId, this.httpService.getHeaders(to));
    }));
  }
  getPropertyParticipants(id: string) {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get(this.confService.getUrlApiEndPoint() + 'api-v2/property/team-tokens/' + id, this.httpService.getHeaders(to));
    }));
  }

  sendNotification(message : any): Observable<any> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<any>( 'https://fcm.googleapis.com/fcm/send', JSON.stringify(message) ,
      {

      headers: new HttpHeaders({
          'Authorization': 'key=' + 'AAAAsr1ucws:APA91bGHvOMnUlN8hH1gzZ9302fHEObTJyR_cobh1gat7c16IeJNim6RmdA1Kq49I2YqGEbXB7vkNSjXOE91GsNuzl4kpdBmJIVc97tlqYpeinNJ7qJFOQJcz3QzxjsCGrOXWAtM1sH2',
          'Content-Type': 'application/json',
        }),
      });
    }));
  }
  pushLastMessageTimeToBackend(propertyId: string) {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get(this.confService.getUrlApiEndPoint() + 'api-v2/property/last-chat-time/' + propertyId, this.httpService.getHeaders(to));
    }));
  }


  searchSimpleProperties(request: SearchSimplePropertyRequest): Observable<Page<PropertySimpleDto>>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Page<PropertySimpleDto>>(this.confService.getUrlApiEndPoint() + 'api-v2/property/search-simple-properties', request, this.httpService.getHeaders(to));
    }));
  }
  selectAllIds(request: SearchSimplePropertyRequest): Observable<string[]>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<string[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/select-ids', request, this.httpService.getHeaders(to));
    }));
  }
  getAllMyPropertiesTags(): Observable<string[]>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<string[]>(this.confService.getUrlApiEndPoint() + 'api-v2/property/my-tags', this.httpService.getHeaders(to));
    }));
  }
}
