import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {filter, takeUntil} from 'rxjs/operators';
import {AbstractWindow} from './core/abstract/AbstractWindow';
import {FirebaseService} from './core/endpoints/FirebaseService';
import {IntercomService} from './core/endpoints/IntercomService';
import {SlackService} from './core/endpoints/SlackService';
import {TokenService} from './core/endpoints/TokenService';
import {PersonEndpoint} from './core/endpoints/YaagoEndpoints/PersonEndpoint';
import {RecommendationService} from './core/endpoints/YaagoEndpoints/RecommendationService';
import {BugsnagChunkLoadErrorHandler} from './core/handler/BugsnagChunkLoadErrorHandler';
import {IconService} from './core/services/icon.service';
import {LoaderService} from './core/services/LoaderService';
import {SnackbarService} from './core/services/SnackbarService';
import {UtilsService} from './core/services/UtilsService';
import {PersonService} from './core/services/YaagoServices/PersonService';
import {FirebaseUser} from './model/firebase/FirebaseUser';
import {Person} from './model/Person';
import {Permissions} from "./model/PlatformRole";
import {PropertyService} from './core/services/YaagoServices/PropertyService';
import {CrispService} from "./core/endpoints/CrispService";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractWindow implements OnDestroy, OnInit {
  private firebaseUser: FirebaseUser;
  public yaagoUser: Person;

  public urlAfterRedirects: string = '';
  viewMenu = true;
  private displayedViews = [
    '/recommendation' ,
    '/dashboard',
    '/property',
    '/property/multiple',
    '/setup',
    '/chat',
    '/property/add',
    '/calendar',
    '/calendar/month',
    '/statistics',
    '/traveler/new-rec',
    '/setup/change-password',
    '/setup/person-invites',
    '/traveler/new-rec',
    '/property/yaag-list'];

  currentRegistrationPerson: Person;

  constructor(private firebaseService: FirebaseService,
              private loaderService: LoaderService,
              private tokenService: TokenService,
              private propertyService: PropertyService,
              public translateService: TranslateService,
              public intercomService: IntercomService,
              public crispService: CrispService,
              public utilsService: UtilsService,
              private slackService: SlackService,
              private router: Router,
              location: Location,
              private recommendationService: RecommendationService,
              private personEndpoint: PersonEndpoint,
              private ccService: NgcCookieConsentService,
              private snackbarService: SnackbarService,
              private personService: PersonService,
              private bugsnagChunkLoadErrorHandler: BugsnagChunkLoadErrorHandler,
              private iconService: IconService,
              private gaSrv: GoogleAnalyticsService,
              public activeRoute: ActivatedRoute) {
    super(intercomService, translateService, utilsService);
    this.loaderService.showFullLoader('');
    this.personService.getCurrentUserObservable().subscribe( user => {
      this.yaagoUser = user;
    });

    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this.onDestroy)).subscribe((e: NavigationEnd) => {

        this.urlAfterRedirects = e.urlAfterRedirects;

      this.viewMenu =
        this.displayedViews.includes(e.urlAfterRedirects) ||
        (
          e.urlAfterRedirects.startsWith('/calendar/')
          && (!e.urlAfterRedirects.startsWith('/calendar/guest-card-beta') || this.isDesktopMode())
        );
    });

    // Google Analytics routing
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.gaSrv.pageView(event.urlAfterRedirects);
        }
      }
    );
    this.manageTranslation();
    this.setupCookieDialog();
    this.manageUser();
    this.manageServiceWorker();
    this.intercomService.init();
    this.iconService.registerIcons();

  }

  ngOnInit(): void {
    this.loaderService.showFullLoader('');
    this.setupCookieEvents();
  }

  manageUser() {
    if (this.router.url.startsWith('/support/widget/pp')) {
      this.loaderService.dismissLoader();
      this.router.navigate(['/support/widget/pp']).then(() => this.loaderService.dismissLoader());
      return;
    }
    this.loaderService.showFullLoader('LOGIN');
    this.activeRoute.queryParams.forEach( v=> {
      if (v["_bookingsync_account_id"]) {
        localStorage.setItem("BookingSyncAccountId", v["_bookingsync_account_id"]);
      }
    });
    console.log("-->BookingSyncQueryParams:", this.activeRoute);
    // if there is no user in the localStorage (du to previous logout) then don't check the currentState to AutoLogin
    console.log("firebaseCurrentUser", this.firebaseService.getCurrentUser());
    this.firebaseService.firebaseUserState().pipe(takeUntil(this.onDestroy)).subscribe(user => {
      if (user) {
        this.firebaseUser = JSON.parse(JSON.stringify(user));
        this.firebaseService.setCurrentUser(this.firebaseUser);
        this.tokenService.setToken(this.firebaseUser.stsTokenManager.accessToken);

        try {
          this.personEndpoint.getGuruUser().subscribe(u => {
            if (!u) {
              this.personService.saveMiniMe(this.firebaseUser);
            } else {
              if (!user.emailVerified && user.providerData.some((p) => p.providerId === 'password')
                && !this.router.url.startsWith('/login/check')
                && !this.router.url.startsWith('/support')
                && !this.router.url.startsWith('/checkout')
                && !this.router.url.startsWith('/login/verify')) {
                console.log('user with unverified email');
                this.personService.setCurrentUser(u);
                this.firebaseUser = this.firebaseService.getCurrentUser();
                this.firebaseUser.yaagoUser = u;
                this.firebaseService.setCurrentUser(this.firebaseUser);
                this.router.navigate(['/login/verify']).then(() => this.loaderService.dismissLoader());
              } else if (u.trialPeriodOver
                  || u.closed
                  // No more check for this || (u.subscriptionName === 'premium' && !u.hasPaymentMethod)
                  || (u.failedInvoices != null && u.failedInvoices.length > 0)) {
                // test of the Trial Period Over Or Invoice Failure ...
                this.router.navigate(['/login/trial-over']).then(() => this.loaderService.dismissLoader());
              } else {
                console.log('i am a user', u);
                this.personService.setCurrentUser(u);
                this.firebaseUser = this.firebaseService.getCurrentUser();
                this.firebaseUser.yaagoUser = u;


                // CUSTOMISATION DES COULEURS
                if (this.firebaseUser.yaagoUser.corporate != null) {

                  this.utilsService.manageCorporateColors(this.firebaseUser.yaagoUser.corporate);

                }

                this.firebaseService.setCurrentUser(this.firebaseUser);
                this.intercomService.setUser(this.firebaseService.getCurrentUser());
                this.crispService.init();
                this.crispService.setUser(this.firebaseService.getCurrentUser());
                if (this.utilsService.hasPermission(Permissions.SETTINGS_NO_SUPPORT, null)) {
                  this.crispService.showMessenger();
                  this.crispService.hideMessanger();
                }
                window['yaagoUser'] = this.firebaseUser.email;

                this.yaagoUser = u;
                window['yaagoUser'] = this.firebaseUser.email;

                console.log('routerUrl:', this.router.url);
                if (this.router.url.startsWith('/login') && this.router.url !== '/login/reset-pwd' && this.router.url !== '/login/trial-over') {
                  console.log('oui');
                  console.log(this.yaagoUser);
                  if ((this.yaagoUser.pendingInvitations != null && this.yaagoUser.pendingInvitations.length > 0) || (this.yaagoUser.pendingCohostInvitations != null && this.yaagoUser.pendingCohostInvitations.length > 0)) {
                    // we want to redirect on pending invites when there is some pending
                    this.router.navigate(['/setup/invites']).then(() => this.loaderService.dismissLoader());
                  } else if (this.yaagoUser.onBoarding.onBoarded) {
                    if (this.yaagoUser.influencerDetails != null) {
                      this.router.navigate(['/recommendation']).then(() => this.loaderService.dismissLoader());
                    } else {
                      if (this.utilsService.hasPermission(Permissions.PROPERTY_LISTING, null)) {
                        //if (this.yaagoUser?.corporate?.multipleViewAsDefault) {
                          this.router.navigate(['/property/multiple']).then(() => this.loaderService.dismissLoader());
                        //} else {
                          //this.router.navigate(['/property']).then(() => this.loaderService.dismissLoader());
                        //}
                      } else if (this.utilsService.hasPermission(Permissions.TASKS_LISTING, null)) {
                        this.router.navigate(['/dashboard']).then(() => this.loaderService.dismissLoader());
                      } else {
                        this.router.navigate(['/calendar/month']).then(() => this.loaderService.dismissLoader());
                      }
                    }
                    } else {
                      this.router.navigate(['register/registration']).then(() => this.loaderService.dismissLoader());
                  }
                } else {
                  this.loaderService.dismissLoader();
                }
              }
            }
            }, err => {
              console.log(err);
              if (err.status === 400) {

                if (this.firebaseService.getCurrentRegisterUser() != null) {
                  this.currentRegistrationPerson = this.firebaseService.getCurrentRegisterUser();
                  this.personService.saveMiniMeFirstFirebaseUser(this.firebaseUser, this.currentRegistrationPerson.phone,
                    this.currentRegistrationPerson.personCategory, this.currentRegistrationPerson.firstName, this.currentRegistrationPerson.lastName);
                  this.firebaseService.removeCurrentRegisterUser();
                } else {

                  // Cas sign In Google or Facebook

                  let socialMediaPerson = new Person();
                  socialMediaPerson.firstName = this.firebaseUser.displayName;
                  socialMediaPerson.email = this.firebaseUser.email;
                  socialMediaPerson.phone = this.firebaseUser.phoneNumber;
                  socialMediaPerson.emailVerified = true;

                  this.loaderService.dismissLoader();
                  this.router.navigate(['/sign-in'], { queryParams: {person: JSON.stringify(socialMediaPerson) }}).then();

                }
              }
          });
        } catch (err) {
          console.log('there is an error with the Backend ...');
          console.log('Yaago user seems to do not exists');
        }
      } else {
        this.personService.setCurrentUser(null);
        this.firebaseUser = null;
        this.yaagoUser = null;
        console.log('no user logged: you need to sign up');
        console.log(this.router.url);
        if (!this.router.url.startsWith('/register')
          && !this.router.url.startsWith('/sign-in')
          && !this.router.url.startsWith('/login/check')
          && !this.router.url.startsWith('/checkout')
          && !this.router.url.startsWith('/support')
          && !this.router.url.startsWith('/login/verify')
          && !this.router.url.startsWith('/login/reset-pwd')) {
          console.log('enter in start-with');
          this.router.navigate(['/login']).then(() => this.loaderService.dismissLoader());
        } else {
          console.log('do not enter in start-with');
          this.loaderService.dismissLoader();
        }
      }
    });
  }
  manageTranslation() {
    if (this.utilsService.yaagoTranslationLanguages.indexOf(this.translateService.getBrowserLang()) > -1) {
      this.translateService.use(this.translateService.getBrowserLang());
      this.selectedValue = this.translateService.getBrowserLang();
    } else {
      this.translateService.use('en');
      this.selectedValue = 'en';
    }

    this.translateService.onLangChange.subscribe(() => {
      // console.log('Translate Service Language has changed :' + this.translateService.currentLang);
      this.translateService.use(this.translateService.currentLang);
      this.selectedValue = this.translateService.currentLang;
    });
  }
  logout() {
    this.firebaseService.signOut();
  }
  unregisterServiceWorker() {
    if (this.isServiceWorkerCompatible(window) && window.location.origin.indexOf('localhost') < 0) {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/ngsw-worker.js', { scope: '/' })
          .then( (reg: ServiceWorkerRegistration) => {
              reg.unregister().then(() => {
                console.log('Service worker unregistration succeeded:');
              }, err => {
                console.log('Service worker unregistration failed:', err);
              });
            },
            (err) => {
              console.log('Service worker registration failed:', err);
            }
          );
      }
    }
  }
  manageServiceWorker() {
    console.log('origin', window.location.origin);
    if (this.isServiceWorkerCompatible(window) && window.location.origin.indexOf('localhost') < 0) {
      console.log('Service worker register...');
      if (navigator.serviceWorker) {
        console.log('Service worker register2...');
        navigator.serviceWorker.register('/ngsw-worker.js', { scope: '/' })
          .then( (reg: ServiceWorkerRegistration) => {
              console.log("toto", reg);
              console.log('Service worker registration succeeded:', reg);
              reg.unregister();
            },
            (err) => {
              console.log('Service worker registration failed:', err);
            }
          );
      }
    }
  }

  private setupCookieEvents() {
    console.log('setup cookie event');
    // subscribe to cookieconsent observable to react to main events
    this.ccService.popupOpen$.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
      console.log('popup open');
    });

    this.ccService.popupClose$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popup close');
      });

    this.ccService.initialize$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('init: ' + event);
        console.log(event);
      });

    this.ccService.statusChange$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(statuschange: '');
        console.log(event);
      });

    this.ccService.revokeChoice$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('revoke');
      });

    this.ccService.noCookieLaw$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('no cookie law');
      });
  }
  private setupCookieDialog() {
    this.translateService
      .get([
        'cookies.title',
        'cookies.message',
        'cookies.message2',
        'cookies.allow',
        'cookies.cookie-policy',
        'cookies.terms-and-conditions',
      ])
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.ccService.getConfig().position = 'bottom';
        this.ccService.getConfig().layout = 'custom-layout';
        this.ccService.getConfig().layouts = {
          'custom-layout': '{{messagelink}}{{compliance}}',
        };
        this.ccService.getConfig().elements =
          this.ccService.getConfig().elements || {};
        // TOFIX: Actually using href instead of routerLink because of a bug in the library.
        this.ccService.getConfig().elements.messagelink = `
        <div class="cookie-container">
          <p class="title">{{title}}</p>
          <div class="separator"></div>
          <p class="cookie-content">
            {{message}}
            <a href="#/privacy/cookies" aria-label="Learn more about cookies" tabindex="0" class="cookie-link">{{cookiePolicyLink}}</a> {{message2}}
            <a href="#/privacy/terms-and-conditions" aria-label="Learn more about our Terms and conditions" tabindex="1" class="cookie-link">{{termsAndConditionsLink}}</a>
          </p>
        </div>
      `;
        this.ccService.getConfig().elements.dismiss = `
        <div class="cookie-btn cc-btn cc-dismiss">
          <!--<img src="../assets/icons/icon-cookie.svg">-->
          <span>{{dismiss}}</span>
          <!--<img src="../assets/icons/icon-allow-accept.svg">-->
        </div>
      `;
        this.ccService.getConfig().window = `
        <div id="cookie-dialog" role="dialog" aria-label="cookieconsent" aria-describedby="cookieconsent:desc"
        class="cookie-dialog cc-window {{classes}}">
          <div class="cookie-dialog-content">
            {{children}}
          </div>
        </div>
      `;
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.title = data['cookies.title'];
        this.ccService.getConfig().content.message = data['cookies.message'];
        this.ccService.getConfig().content.message2 = data['cookies.message2'];
        this.ccService.getConfig().content.dismiss = data['cookies.allow'];
        this.ccService.getConfig().content.cookiePolicyLink =
          data['cookies.cookie-policy'];
        this.ccService.getConfig().content.termsAndConditionsLink =
          data['cookies.terms-and-conditions'];

        this.ccService.destroy(); // Remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with custom layout
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getProductionColor() {
      if (window.location.origin.indexOf('app.', 0) > 0) {
        return '';
      } else {
        return 'class-menu-non-production';
      }
  }
  handleScroll($event) {
    const divContainer = document.getElementById("scrollable-container");
    this.propertyService.setEventScroll(divContainer.scrollTop);
  }
}
