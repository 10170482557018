import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {Property, PropertyLight} from '../../../model/Property';
import {Injectable} from '@angular/core';
import {KeyringEndPoint} from '../../endpoints/YaagoEndpoints/KeyringEndPoint';
import {GuideEndpoint} from '../../endpoints/YaagoEndpoints/GuideEndpoint';
import {UsefulNumberEndpoint} from '../../endpoints/YaagoEndpoints/UsefulNumberEndpoint';
import {UsefulNumber} from '../../../model/UsefulNumber';
import {PropertyEndpoint} from '../../endpoints/YaagoEndpoints/PropertyEndpoint';
import {catchError, map, tap} from 'rxjs/operators';
import {PropertyBooking} from '../../../model/PropertyBooking';
import {UtilsService} from '../UtilsService';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  constructor(private keyringEndPoint: KeyringEndPoint,
              private usefulNumberEndpoint: UsefulNumberEndpoint,
              private propertyEndpoint: PropertyEndpoint,
              private guideEndpoint: GuideEndpoint,
              private utils: UtilsService) {

  }


  public globalFilterTaskStartDate = '';
  public globalFilterTaskEndDate = '';
  public globalFilterPropertyTitle = '';
  public globalFilterSelectedTag: string[] = [];
  public globalFilterMyTasks = false;
  public globalFilterCorporateId: string = null;

  private eventScroll = new BehaviorSubject<number>(0);
  private eventScroll$ = this.eventScroll.asObservable();

  getEventScroll(): Observable<number> { return this.eventScroll$; }
  setEventScroll(refNumber: number) { this.eventScroll.next(refNumber); }

  private globalNumberOfProperties = new BehaviorSubject<number>(0);
  private globalNumberOfProperties$ = this.globalNumberOfProperties.asObservable();

  private globalCurrentProperty = new BehaviorSubject<Property>(undefined);
  private globalCurrentProperty$ = this.globalCurrentProperty.asObservable();
  private globalCurrentProperties = new BehaviorSubject<Property[]>(undefined);
  private globalCurrentProperties$ = this.globalCurrentProperties.asObservable();
  private _propertiesUpdated = new BehaviorSubject<Property>(undefined);
  propertiesUpdated$ = this._propertiesUpdated.asObservable();

  getCurrentProperty(): Observable<Property> { return this.globalCurrentProperty$; }
  setCurrentProperty(refProperty: Property) { this.globalCurrentProperty.next(refProperty); }

  getCurrentProperties(): Observable<Property[]> {
      return this.globalCurrentProperties$;
  }
  setCurrentProperties(refProperties: Property[]) {
    /*refProperties = refProperties.sort((p1, p2) => {
      const compare = p1.title.toLowerCase().localeCompare(p2.title.toLowerCase());
      return compare;
    });*/
    this.globalCurrentProperties.next(refProperties);
    this.globalNumberOfProperties.next(refProperties.length);
  }

  getNumberOfProperties() {
    return this.globalNumberOfProperties$;
  }
  isEnabled(property: Property): boolean {
    return true;
    // return property.membership && (property.membership === Membership.FREE || property.membership === Membership.PREMIUM ||
    //  property.membership === Membership.TRIAL || property.membership === Membership.CORPORATE ||
    //  property.membership === Membership.STANDARD);
  }


  getMyProperties(): Observable<Property[]> {
    return this.propertyEndpoint.getMyProperty().pipe(map(properties => {
      return this.sortPropertiesByCompletionOrTitle(properties);
    }));
  }
  getMyEnabledProperties(): Observable<Property[]> {
    return this.getMyProperties().pipe( map (ps => {
      return ps.filter(p => this.isEnabled(p) && !p.stripeSubscriptionPaused);
    }));
  }
  sortPropertiesByCompletionOrTitle(properties: Property[]) {
    return properties.sort((p1, p2) => {
      return p1.title.localeCompare(p2.title);
    });
  }
  getMainPhoto(property: Property): string {
    let mainPhoto;
    if (property != null && property.photos != null) {
      mainPhoto = property.photos.find(photo => photo.main === true);
      if (mainPhoto == null && property.photos.length > 0) {
        mainPhoto = property.photos[0];
      }
    }
    return mainPhoto ? mainPhoto.url : '/assets/icons/property-list/picture-no-photo-global.svg';
  }

  getMainPhotoMini(property: Property): string {
    let mainPhoto;
    if (property != null && property.photos != null) {
      mainPhoto = property.photos.find(photo => photo.main === true);
      if (mainPhoto == null && property.photos.length > 0) {
        mainPhoto = property.photos[0];
      }
    }
    return mainPhoto ? mainPhoto.url.replace("aki_policy=medium", "aki_policy=x_small") : '/assets/icons/property-list/picture-no-photo-global.svg';
  }

  registerKeyring(propertyId, keycode) {
    this.keyringEndPoint.registerKeyring(propertyId, keycode).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  unRegisterKeyring(propertyId: string) {
    this.keyringEndPoint.unRegisterKeyring(propertyId).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  createUsefulNumber(propertyUsefulNumber: UsefulNumber, propertyId: string) {
    this.usefulNumberEndpoint.createUsefulNumber(propertyUsefulNumber, propertyId).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  updateUsefulNumber(propertyUsefulNumber: UsefulNumber, propertyId: string) {
    this.usefulNumberEndpoint.updateUsefulNumber(propertyUsefulNumber, propertyId).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  deleteUsefulNumber(propertyUsefulNumberId: string, propertyId: string) {
    this.usefulNumberEndpoint.deleteUsefulNumber(propertyUsefulNumberId, propertyId).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  createProperty(property: Property) {
    this.propertyEndpoint.createProperty(property).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  updateProperty(property: Property) {
    this.propertyEndpoint.updateProperty(property).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }
  deleteProperty(property: Property): Observable<void> {
    return this.propertyEndpoint.deleteProperty(property);
  }
  deletePropertyById(propertyId: string): Observable<void> {
    return this.propertyEndpoint.deletePropertyById(propertyId);
  }
  getMyProperty() {
    this.propertyEndpoint.getMyProperty().subscribe( p => {
      this.setCurrentProperties(p);
    });
  }

  /**
   * @deprecated - Really unefficient and creates memory leaks, the below method should be used instead.
   * @param id property Id
   */
  getPropertyById(id: string) {
    this.propertyEndpoint.getPropertyById(id).subscribe( p => {
      this.setCurrentProperty(p);
    });
  }



  getPropertyAndSetAsCurrent(propertyId: string): Observable<Property> {
    return this.propertyEndpoint.getPropertyById(propertyId).pipe(tap(p => this.setCurrentProperty(p)));
  }

  enableDisableAction(propertyId: string, flag: boolean): Observable<Property> {
    return this.propertyEndpoint.enableDisableAction(propertyId, flag);
  }

  mergeProperties(firstPropertyId , secondPropertyId): Observable<Property[]> {
    return this.propertyEndpoint.mergeProperties(firstPropertyId, secondPropertyId);
  }

  getCurrentBooking(propertyId: string): Observable<PropertyBooking> {
    return this.propertyEndpoint.getCurrentPropertyBooking(propertyId).pipe(catchError(error => of(error)));
  }

  getCurrentBookingForProperties(properties: Property[]): Observable<PropertyBooking[]> {
    const obs = properties.map(property => this.getCurrentBooking(property.id));
    return forkJoin(obs);
  }

  hasMemberOrInvites(property: Property): boolean {
    if (!property) {
      return false;
    }
    const fcoHostInvitations = property.cohostInvitations ?
      property.cohostInvitations.filter(i => 'accepted' !== i.status.toLowerCase()) :
      [];

    const fteammateInvitations = property.teammateInvitations ?
      property.teammateInvitations.filter(i => 'accepted' !== i.status.toLowerCase()) :
      [];

    return fcoHostInvitations.length > 0 ||
      fteammateInvitations.length > 0 ||
      property.teammates && property.teammates.length > 0 ||
      this.utils.getCoHosts(property).length > 0;
  }

  setCurrentPropertiesCount(length: number) {
    this.globalNumberOfProperties.next(length);
  }

  public getPropertyLightFromProperty(p: Property): PropertyLight {
    const propertyLight = new PropertyLight();
    if (p != null) {
      propertyLight.title = p.title;
      propertyLight.pictureUrl = this.getMainPhoto(p);
      propertyLight.fullAddress = p.fullAddress;
    }
    return propertyLight;
  }
  getPropertyParticipants(id: string) {
    this.propertyEndpoint.getPropertyParticipants(id).subscribe( res => {

    });
  }
  sendNotification(message: any) {
    this.propertyEndpoint.sendNotification(message).subscribe( res => {
     console.log("message",res)
    });
  }
  pushLastMessageTimeToBackend(propertyId: string) {
    this.propertyEndpoint.pushLastMessageTimeToBackend(propertyId).subscribe( res => {

    });
  }
}
