import {HttpClient} from '@angular/common/http';
import {HttpService} from '../HttpService';
import {TokenService} from '../TokenService';
import {ConfService} from '../../services/ConfService';
import {Injectable} from '@angular/core';
import {Guide} from '../../../model/Guide';
import {Observable} from 'rxjs';
import {Property} from '../../../model/Property';
import {flatMap} from 'rxjs/operators';
import {Corporate, CustomGuideCategoriesPictures} from '../../../model/Corporate';

@Injectable({ providedIn: 'root' })
export class GuideEndpoint {
  constructor(private http: HttpClient,
              private httpService: HttpService,
              private tokenService: TokenService,
              private confService: ConfService) {
  }
  copyGuideToProperties(propertyId: string, guideId: string, targetPropertiesIds: string[]): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/copy-to-properties', {propertyId, guideId, targetPropertiesIds}, this.httpService.getHeaders(to));
    }));
  }
  guideCopyFrom(fromPropertyId: string, toPropertyId: string, selectedGuideIds: string[]): Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/copyfrom/' + fromPropertyId + '/' + toPropertyId, selectedGuideIds, this.httpService.getHeaders(to));
    }));
  }
  updateSortedPropertyGuide(propertyId: string, guides: Guide[]) {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/sorted/' + propertyId, guides, this.httpService.getHeaders(to));
    }));
  }
  updateCustomCategoryPicture(propertyId: string, custom: CustomGuideCategoriesPictures): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.put<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/category-picture/' + propertyId, custom, this.httpService.getHeaders(to));
    }));
  }
  deleteCustomCategoryPicture(propertyId: string, cat: string): Observable<Corporate> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Corporate>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/category-picture/' + propertyId + '/' + cat, this.httpService.getHeaders(to));
    }));
  }
  setUrlForGuide(propertyId: string, guideId: string): Observable<Property>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/' + propertyId + '/' + guideId, null, this.httpService.getHeaders(to));
    }));

  }

  addLanguage(propertyId: string, language: string) : Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/editable-language/' +  propertyId + '/' + language, this.httpService.getHeaders(to));

    }));

  }
  deleteLanguage(propertyId: string, language: string): Observable<Property>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.delete<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/editable-language/' +  propertyId + '/' + language, this.httpService.getHeaders(to));
    }));

  }

  exportGuideOrder(objToSend): Observable<void>  {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.post<void>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/export-category-order', objToSend, this.httpService.getHeaders(to));
    }));

  }

  removeAllLanguages(propertyId: string) : Observable<Property> {
    return this.tokenService.getNewToken().pipe(flatMap( to => {
      return this.http.get<Property>(this.confService.getUrlApiEndPoint() + 'api-v2/property/guide/remove-all-translation/' +  propertyId, this.httpService.getHeaders(to));
    }));

  }


}
