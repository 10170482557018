import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable, of } from 'rxjs';

const AUTH_TOKEN = 'yaago_auth_token';

@Injectable({ providedIn: 'root' })
export class TokenService {

  constructor(private angularFireAuth: AngularFireAuth) {
  }

  refreshTokenPromise(firstRefresh = true): Promise<string> {
    console.log('Refresh', firstRefresh);
    let canGetToken = true;
    if (this.angularFireAuth == null) {
      console.log('getToken: angularFireAuth is NULL !! ');
      canGetToken = false;
    } else {
      if (this.angularFireAuth.auth == null) {
        console.log('getToken: angularFireAuth.auth is NULL !! ');
        canGetToken = false;
      } else {
        if (this.angularFireAuth.auth.currentUser == null) {
          console.log('getToken: angularFireAuth.auth.currentUser is NULL !! ');
          canGetToken = false;
        }
      }
    }

    if (canGetToken) {
      return this.angularFireAuth.auth.currentUser.getIdToken(true);
    } else {
      return firstRefresh ? this.refreshTokenPromise(false) : Promise.reject();
    }
  }

  getToken(): string {
    return localStorage.getItem(AUTH_TOKEN);
  }

  setToken(token: string) {
    if (token) { localStorage.setItem(AUTH_TOKEN, token); }
  }

  removeToken() {
    return localStorage.removeItem(AUTH_TOKEN);
  }

  public parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }

  public getNewToken(): Observable<string> {
    let retToken: string;
    const token = this.getToken();
    // console.log("--> token:", token);
    if (token) {
      const anyToken = this.parseJwt(token);

      if (((anyToken.exp * 1000) - Date.now()) / (60 * 1000) < 2) {
        return from(this.refreshTokenPromise().then(newToken => {
          this.setToken(newToken);
          const anyTokenNew = this.parseJwt(newToken);
          retToken = newToken;
          return newToken;
        }));
      }
    }
    return of(token);
  }

}
