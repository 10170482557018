
export class PlatformRole {
   id: string;
   displayableName: string;
   uniqueName: string;
   yaagoRole: boolean;
   enabled = true;
   permissionBlocs: PermissionBloc[];
  // when the role is inside corporate
  changedByCorporate;
}

export  class PermissionBloc {
  id: string;
  name: string;
  tag: string;
  enabled: boolean;
  level: number;
  permissionList: PermissionItem[] = [];
}

export class PermissionItem {
  name: string;
  level: number;
  enabled: boolean;
  linkedTo: string[];
}

export enum Permissions {

  // BOOKING
  BOOKING_CALENDARS = 'booking-calendars',
  BOOKING_DETAILS_VIEW = 'booking-details-view',
  BOOKING_EDITION = 'booking-edition',
  BOOKING_ASSIGN_TEAM_MEMBER = 'booking-assign-team-member',
  BOOKING_DELETION = 'booking-deletion',
  BOOKING_OWNER_BOOKING_DELETION = 'booking-owner-booking-deletion',
  BOOKING_EARNING_VIEW = 'booking-earning-view',
  BOOKING_GUEST_VIEW = 'booking-guest-infos-view',
  BOOKING_COMMUNICATION = 'booking-communication',
  BOOKING_DASHBOARD_ALERTS = 'booking-dashboard-alerts',
  BOOKING_BOOKLET_REVIEW = 'booking-booklet-review',
  BOOKING_ONLINE_CHECK = 'booking-online-check',
  BOOKING_ACTIVATE_SERVICES = 'booking-activate-services',
  BOOKING_GUEST_BOOKLET = 'booking-guest-booklet',
  BOOKING_INTERNALCHAT = 'internal-chat',
  BOOKING_CORPORATENOTE = 'coporate-note',
  OWNER_BOOKING_CREATION = 'booking-owner-booking-creation',

  // PROPERTY
  PROPERTY_LISTING = 'property-listing',
  PROPERTY_DETAILS_VIEW = 'property-details-view',
  PROPERTY_RAW_BOOKLET = 'property-raw-booklet',
  PROPERTY_EDITION = 'property-edition',
  PROPERTY_MERGING = 'property-merging',
  PROPERTY_DELETION = 'property-deletion',
  PROPERTY_NEW_PROPERTY_BY_DEFAULT = 'property-new-property-by-default',
  PROPERTY_SERVICE_LISTING = 'property-service-listing',
  PROPERTY_MESSAGES_VIEW = 'property-messages-view',
  PROPERTY_CALENDARS_VIEW = 'property-calendars-view',
  PROPERTY_CALENDAR_ADDING = 'property-calendars-adding',
  PROPERTY_CALENDAR_DELETION = 'property-calendars-deletion',
  PROPERTY_NUMUTIL_VIEW = 'property-numutil-view',
  PROPERTY_NUMUTIL_EDITION = 'property-numutil-edition',
  PROPERTY_NUMUTIL_DELETION = 'property-numutil-deletion',
  PROPERTY_FINANCES_VIEW = 'property-finances-view',
  PROPERTY_KEYRINGS_VIEW = 'property-keyrings-view',
  PROPERTY_HYGIENE_VIEW = 'property-hygiene-view',
  PROPERTY_DASHBOARD_CONNECTED_DEVICES = 'property-dashboard-connected-devices',
  PROPERTY_ACCESS_INTERNAL_CHAT = 'property-access-internal-chat',


  // GUIDES
  GUIDES_UPDATE = 'guide-update',
  GUIDES_DELETION = 'guide-deletion',
  GUIDES_LISTING = 'guide-listing',


  //TASKS
  TASKS_LISTING = 'tasks-listing',
  TASKS_ASSIGNING = 'tasks-assigning',
  TASKS_NO_VALIDATION = 'tasks-no-validation',
  TASKS_DELETION = 'tasks-deletion',
  TASKS_SEE_ONLY_MINE = 'tasks-see-only-my-tasks',
  TASKS_UNDO_TASK = 'tasks-undo-task',
  TASKS_EDIT_DATE = 'tasks-edit-date',
  TASKS_CAN_REJECT = 'tasks-can-reject',

  // SETTINGS
  SETTINGS_USERS_ROLES_MANAGEMENT = 'settings-user-role-management',
  SETTINGS_SUBSCRIPTION_VIEW = 'settings-subscription-view',
  SETTINGS_ACCOUNT_PARAMETERS = 'settings-myaccount-parameters',
  SETTINGS_ACCOUNT_CORPORATE = 'settings-myaccount-corporate',
  SETTINGS_ACCOUNT_COMMUNICATION = 'settings-myaccount-communication',
  SETTINGS_ACCOUNT_BOOKLET = 'settings-myaccount-booklet',
  SETTINGS_ACCOUNT_ONLINE_CHECKIN = 'settings-myaccount-checkin-online',
  SETTINGS_PLATFORM_CONNECTIONS = 'settings-platforms-connections',
  SETTINGS_PROPERTY_OWNER_OTA_MANAGEMENT = 'settings-property-owner-ota-management',
  SETTINGS_CONNECTED_SERVICES = 'settings-connected-services',
  SETTINGS_KEYRING_MASS_SETUP = 'settings-keyrings-mass-setup',
  SETTINGS_RECOMMENDATIONS = 'settings-recommendations',
  SETTINGS_ROLE_ONLY_MYTEAM = 'settings-role-only-myteam',
  SETTINGS_NO_SUPPORT = 'settings-no-support'
}
export enum Modules {
  CHECKIN_ONLINE = 'CHECKIN_ONLINE',
  INTERNAL_CHAT = 'INTERNAL_CHAT',
  MOBILE_APP = 'MOBILE_APP',
  WHITE_LABEL = 'WHITE_LABEL'
}
